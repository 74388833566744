& {
  z-index: 2;
  @extend .bg-primary;
  @extend .text-white;
}

.navbar-brand {
  height: 68px + ($navbar-brand-padding-y * 2);
}

.navbar {
  @extend .navbar-dark;
}

.nav-item {
  position: relative;

  &::before {
    transition: 0.5s all;
    content: " ";
    position: absolute;
    top: calc(var(--bs-nav-link-padding-y) + 8px);
    bottom: calc(var(--bs-nav-link-padding-y) + 8px);
    left: 0;
    background-color: $gray-600;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      width: 1px;
      display: block;
    }
  }

  /* decoration on hover */
  &::after {
    transition: 0.5s all;
    content: " ";
    position: absolute;
    top: 0;
    left: 25%;
    right: 75%;
    background-color: $gray-600;
    display: block;
    height: 1px;
  }

  &:hover::after {
    right: 25%;
  }
}

.linkBtn {
  color: $primary;
  width: 25px;
  height: 25px;
  padding: 5px;
  @extend .btn-light;

  &.icon_fb-padding {
    padding-bottom: 0;
    padding-top: 10px;
  }

  svg {
    width: 100%;
  }
}
