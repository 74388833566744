.side_menu-margin {
  margin-bottom: 20px;
  margin-top: 10px;
}

.side_menu-position--fixed {
  display: none;

  @media (min-width: map-get($grid-breakpoints, xl)) {
    transition: 1s all;
    display: block;
    position: fixed;
    z-index: 3;
    right: 0;
    left: 0;

    &.fixedTop {
      transform: translateY(-100px);
    }
  }
}

.side_menu_container-maxwidth {
  /* copy container style */
  @include make-container();

  @media (min-width: map-get($grid-breakpoints, xl)) {
    max-width: map-get($container-max-widths, xl);
  }

  @media (min-width: map-get($grid-breakpoints, xxl)) {
    max-width: map-get($container-max-widths, xxl);
  }

  position: relative;
}

.nav_pills-alignment--right {
  right: 40px;
}

.filterItem {
  font-size: px-to-rem(20);
  letter-spacing: -0.1px;
  position: relative;

  &::after {
    content: "|";
    position: absolute;
    right: 0;
    bottom: 8px;
  }
}

.filterLink {
}
