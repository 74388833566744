.newsCard {
  border-color: #cccccc;
}

.decoration_line-orange {
  width: 22px;
  height: 4px;
  background-color: $secondary;
  top: 2.5px;
  position: relative;
}

.titleStyle {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  position: relative;
}

.decoration_underline {
  height: 1px;
  max-width: 112px;
  min-width: 50%;
  background-color: #cccccc;
}

.card-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  margin-top: 14px;
}
