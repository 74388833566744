& {
  padding: 5px 0px;
  @extend .border-top;
  @extend .border-bottom;
}

$share-icon-height: 22px;

a {
  &:not(.btnBack) {
    width: $share-icon-height;
    height: $share-icon-height;
  }

  padding: 5px;

  &.shareBtn[data-url] {
    cursor: pointer;
  }
}

.badge_info {
  @extend .fw-normal;
  height: $share-icon-height;
  background-color: $secondary;
  padding: 0px 8px;
}

.eyeIcon {
  width: 20px;
}
