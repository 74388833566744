$font-family-base: "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$primary: rgb(142, 132, 184);

/* navbar */
$navbar-padding-y: 22px;
$navbar-brand-padding-y: 2px;
$navbar-dark-color: rgba(white, 0.8);
// $navbar-nav-link-padding-x: px-to-rem(16);

/* card */
$card-border-radius: 10px;
// $card-border-color: $brand-blue;
// $card-cap-bg: white;

/* breadcremb */
// $breadcrumb-bg: white;
// $breadcrumb-padding-x: 0px;
// $breadcrumb-margin-bottom: 60px;

/* pill */
$nav-pills-link-active-bg: transparent;
$nav-pills-link-active-color: $primary;

/* form */
// $input-border-color: transparent;
