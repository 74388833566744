.btnSearch {
  width: 38px;
  height: 100%;

  svg {
    width: 20px;
  }
}

.closeBottomWrapper {
  background-color: rgba($color: #ffffff, $alpha: 0.45);
}

.closeBtn {
  width: 20px;
  height: 20px;

  svg {
    width: 100%;
  }
}

&.desktopPosition {
  top: calc(105px - 22px);
  right: 0;
  width: 280px;
  box-shadow: 2px 5px 5px 0px rgba(0, 0, 0, 0.5);
}
