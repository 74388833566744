img {
  @extend .img-fluid;
  @extend .w-100;
}

.targetImage {
  @extend .w-100;
}

.mainContainer {
  /* self defined filter style */
  @media (min-width: map-get($grid-breakpoints, xl)) {
    padding-right: 200px;
    padding-left: 200px;
  }
}

.metaInfo {
  @extend .border-top;
  @extend .border-bottom;
  color: var(--bs-gray-600);
  @extend .mb-4;
  @extend .py-2;
}

.meta_text-decoration:not(:last-child)::after {
  content: ".";
  width: 34px;
  padding-right: 15px;
  padding-left: 15px;
}

article {
  margin-top: 40px;
  margin-bottom: 40px;
}
