@function px-to-rem($px) {
  @return math.div($px, 16) * 1rem;
}

.img-match-height {
  width: auto;
  max-height: 100%;
}

.obj-fit {
  &--cover {
    object-fit: cover;
  }

  &--contain {
    object-fit: contain;
  }
}
