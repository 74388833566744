#map,
.mapContainer {
  height: 480px;
}

.qijinMap {
  width: 100%;
  height: 100%;
  border: 0;
}
