& {
  @extend .bg-primary;
}

.footer__social-icon--size {
  width: 32px;
  height: 32px;
  padding: 5px;
  color: $primary;

  &.icon_fb-padding {
    padding-top: 10px;
  }
}

hr {
  background-color: #666666;
}

.copyrightContainer {
  font-size: px-to-rem(12);
  line-height: 18px;
  letter-spacing: -0.06px;
}
