.feature__img--height {
  height: 225px;
}

.news__list__arrow {
  &--size {
    width: 12px;
    height: 12px;
  }

  &--padding {
    padding: 2px;
  }

  &--border {
    border: 1px solid currentColor;
    border-radius: 50%;
  }
}
