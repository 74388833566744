.featureImageContainer {
  margin-left: math.div(-$grid-gutter-width, 2);
  margin-right: math.div(-$grid-gutter-width, 2);
}

.feature_image-size {
  height: 320px;
  @extend .obj-fit--cover;
  @extend .w-100;
}

.featureItem_margin-negative {
  @media (min-width: map-get($grid-breakpoints, md)) {
    margin-top: -150px;
  }
}

.articleSnippet {
  padding: 20px math.div($grid-gutter-width, 2);

  @media (min-width: map-get($grid-breakpoints, md)) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.title {
  margin-bottom: 20px;
  @extend .text-secondary;
}

.textLink {
  color: black;

  &.disabled {
    pointer-events: none;
  }

  &:hover {
    text-decoration: none;
  }

  p {
    letter-spacing: -0.08px;
    line-height: 24px;
    font-size: px-to-rem(16);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; // number of lines to show
    -webkit-box-orient: vertical;

    &:hover {
      text-decoration: underline;
    }
  }
}
