@use "sass:math";
@import "custom_bootstrap_vars";
@import "bootstrap";

/* slick variable */
$slick-font-path: "../fonts/vendor-slick/";
$slick-loader-path: "../images/vendor-slick/";
@import "slick-theme.scss";
@import "slick.scss";

/* PhotoSwipe vendor */
$pswp__assets-path: "../images/vendor-photoswipe/";
@import "default-skin.scss";
@import "main.scss";

/* fontaweseom */
// $fa-font-path: "../fonts/vendor-fontawesome";
// @import "fontawesome";
// @import "solid";
// @import "regular";
// @import "brands";

// project specific CSS goes here
/* widgets */
@import "./widgets/font-face";
@import "./widgets/utils";
@import "./widgets/filter-snippet";

.search_bar {
  @import "./widgets/search-bar";
}

.next_prev_navigation {
  @import "./widgets/next-prev-navigation";
}

.social_share_widget {
  @import "./widgets/social-share-icons";
}

/* default */

// layout //
.header {
  @import "./layout/header";
}

main {
  min-height: calc(100vh - 100px - 195px);
}

.footer {
  @import "./layout/footer";
}

.main_background {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

// common snippet //
img {
  @extend .img-fluid;

  &.full-width {
    @extend .w-100;
  }
}

/* component */
.featureSection {
  @import "./components/feature-section";
}

.mapSection {
  @import "./components/map-section";
}

.news {
  @import "./components/news-card";

  &.news__list {
    @import "./pages/news-list";
  }

  &.newsDetails {
    @import "./pages/news-details";
  }
}
